.team-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: start;
  margin: auto;
  margin-top: 100px;
  margin-bottom: 100px;
  width: 75%;
  gap: 100px;
}

.team-img-box {
  height: 350px !important;
  height: 100%;
}

.team-img-box > img {
  height: 100%;
  width: 100%;
}

.team-right-cont {
  display: flex;
  flex-direction: column;
  height: 350px;
  justify-content: center;
  margin: auto;
}

.learn-more-boxs {
  display: flex;
  align-items: center;
  gap: 10px;
}

.learn-more-boxs h6 {
  margin: 0;
}

.team-right-cont h4 {
  font-size: 24px;
  font-weight: bold;
}

.team-right-cont h3 {
  font-size: 38px;
  font-weight: bold;
}

@media (max-width: 850px) {
  .team-container {
    grid-template-columns: 1fr;
    margin-top: -50px;
    width: 90%;
  }
}
