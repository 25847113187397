.newsletter {
  background-color: #F7FDFB;
}

.newsletter-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 100px;
  padding-bottom: 100px;
  width: 75%;
  margin: auto;
}

.newsletter-heading {
  width: 60%;
}

.newsletter-form {
  width: 40%;
}

.newsletter-heading > h4 {
  width: 100%;
  font-size: 38px;
  font-weight: bold;
}

.newsletter-heading > p {
  width: 60%;
}

.email-field {
  width: 70%;
  height: 100%;
}

.submit-btn {
  width: 30%;
  background-color: #00507d;
  color: white;
  height: 100%;
  border-radius: 5px;
}

.email-form {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 50px;
}

@media (max-width: 850px) {
  .newsletter-container {
    display: grid;
    grid-template-columns: 1fr !important;
    width: 90%;
    text-align: center;
  }

  .newsletter-heading {
    width: 100%;
  }

  .newsletter-form {
    width: 100%;
  }

  .newsletter-heading > h4 {
    width: 100%;
  }

  .newsletter-heading > p {
    width: 100%;
  }
  
  .submit-btn {
    width: 60%;
    background-color: #00507d;
    color: white;
    height: 100%;
    border-radius: 5px;
  }
}