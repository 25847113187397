.portfolio {
  width: 75%;
  margin: auto;
  padding-top: 50px;
  padding-bottom: 100px;
}

.portfolio-container {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  gap: 20px;
  margin: auto;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

.pf-cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.pf-card {
  padding: 20px 20px;
  background-color: rgb(215, 215, 215);
  color: #00507d;
  border-radius: 12px;
  margin-left: 10px;
  margin-right: 10px;
}

.pf-card:hover,.pf-card:hover p{
  background-color: #00507d;
  color: white;
  border-radius: 12px;
}

.pf-card h6 {
  font-size: 20px;
  font-weight: 600;
}

.pf-cards div :first-child :last-child {
  background-color: rgb(196, 196, 196);
}

.arrow {
  width: 25px;
  height: 25px;
}

.learn-more {
  display: flex;
  align-items: start;
}

.learn-more {
  text-decoration: none;
  color: #00507d;
}

.learn-more-imging {
  width: 25px !important;
  height: 25px !important;
}

.portfolio>h4 {
  margin: auto;
  width: 100%;
  text-align: center;
  padding-bottom: 50px;
  font-size: 30px;
  font-weight: 600;
}

@media (max-width: 850px) {
  .portfolio {
    overflow-x: hidden;
    width: 100%;
  }

  .portfolio-container {
    display: grid;
    grid-template-columns: 1fr;
    width: 120%;
    gap: 20px;
    margin: auto;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
    overflow-x: scroll;
  }

  .pf-cards {
    grid-template-columns: 1fr;
  }

  .pf-card {
    width: 90%;
    height: 200px;
    margin: auto;
  }

  .pf-card p {
    width: 100%;
  }

  .arrow {
    display: none;
  }

  .learn-more-text {
    width: max-content !important;
  }
}