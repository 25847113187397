.thinking {
  padding-top: 100px;
  padding-bottom: 100px;
  width: 75%;
  margin: auto;
}

.think-heading h3 {
  font-size: 38px;
  font-weight: 600;
}

.think-heading {
  text-align: center;
  margin-bottom: 70px;
}

.thinking-cards {
  display: grid;
  grid-template-columns: 1fr;
  width: 90%;
  margin: auto;
  align-items: center;
  gap: 10px;
}

.cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  text-align: center;
  gap: 10px;
}

.card-img>img {
  width: 100%;
  height: 100%;
}

.see-all-box {
  text-align: center;
  margin: auto;
  height: 50px;
  width: 250px;
  border-radius: 10px;
  background-color: #00507d !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
  background-color: white;
}

.see-all-box a {
  text-decoration: none;
  color: white;
}

.card {
  background-color: white !important;
  height: 850px;
  width: 85%;
  border: 1px solid rgba(0, 0, 0, 0.3);
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
}

.card-header>h4 {
  font-weight: bold;
  margin-top: 10px;
}

.card .card-img .card-header {
  background-color: white !important;
}

.card-header {
  background-color: white !important;
}

.left-arrow-box .right-arrow-box {
  display: flex;
  justify-content: center !important;
}

.starts {
  margin: auto;
}

.thinking-cards {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding-bottom: 50px;
}

@media (max-width: 850px) {
  .right-arrow-box {
    display: none;
  }

  .left-arrow-box {
    display: none;
  }

  .cards {
    display: grid;
    grid-template-columns: repeat(1, 1fr) !important;
    text-align: center;
    gap: 10px;
    margin-top: 20px;
  }

  .thinking {
    width: 90%;
    margin: auto;
  }

  .card {
    width: 97%;
    height: 500px;
  }
}