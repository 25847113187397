.values-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
  width: 75%;
  margin: auto;
  padding-top: 20px;
}

.values-container > div {
  justify-self: center;
}

.values > h3 {
  margin: auto;
  text-align: center;
  color: #00507D;
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 20px;
}

.values {
  margin-top: 100px;
  margin-bottom: 130px;
  padding-top: 50px;
  padding-bottom: 80px;
  background-color: #F5FDFB;
}

@media (max-width: 850px) {
  .values-container {
    grid-template-columns: 1fr 1fr;
    text-align: center;
    width: 90%;
  }
}