.join {
  margin-top: 100px;
  margin-bottom: 100px;
}

.join > h3 {
  text-align: center;
  font-size: 38px;
  line-height: 1;
  font-weight: bold;
}

.join-container {
  width: 90%;
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.join-text-box {
  width: 50%;
  margin: auto;
}

.b1 {
  text-align: center;
  background-color: #00507d;
  color: white;
  height: 50px;
  width: 220px;
}

.b2 {
  text-align: center;
  background-color: black;
  color: white;
  width: 220px;
}

.join-btn-box {
  display: flex;
  gap: 20px;
  width: 100%;
  justify-content: center;
}
