.position {
    height: 50px;
    background-color: rgb(192, 192, 192);
    display: flex;
    justify-content: start;
    width: 100%;  
    align-items: center;
}

.position-container {
    width: 75% !important;
    margin: auto !important;
}

.position-container > p {
    display: flex;
    justify-content: start;
    align-items: center;
    margin: 0;
}