.map-container {
    height: 850px;
    width: 100%;
}

.map-container > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

@media (max-width:800px) {
    .map-container {
        height: 500px;
        width: 100%;
    } 
}