.support-cards-career {
    text-align: center;
    padding-top: 20px;
    background-color: #F5FDFB;
}

.support-cards-career > h3 {
    font-size: 38px;
    color: #00507D;
    font-weight: bold;
}

.support-cards-container-career {
    width: 75%;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    margin: auto;
    justify-content: center;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px;
    gap: 50px;
}

.support-img-anchor > img {
    height: 80px;
    width: 80px;
    margin-bottom: 10px;
}

.support-link {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.support-card-career {
    padding-top: 20px;
    padding-bottom: 40px;
}

.support-text p {
    margin: 0;
}

.support-linkd > h3 {
    font-size: 26px;
    font-weight: bold;
}

@media (max-width:850px) {
    .support-cards-container-career {
        grid-template-columns: 1fr;
        width: 90%;
    }
}