.join {
  margin-top: 100px;
}

.join > h3 {
  text-align: center;
}

.join-container {
  width: 90%;
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.join-text-box {
  width: 50%;
  margin: auto;
}

.b1 {
  text-align: center;
  background-color: #00507d !important;
  color: white !important;
  height: 50px;
  width: 220px;
}

.b2 {
  text-align: center;
  background-color: black !important;
  color: white !important;
  width: 220px;
  height: 50px;
}

.join-btn-box {
  display: flex;
  gap: 20px;
  width: 100%;
  justify-content: center;
}

@media (max-width: 850px) {
  .join {
  }

  .join-btn-box {
    display: grid;
    grid-template-columns: 1fr;
    text-align: center;
    margin: auto;
    width: 100%;
  }

  .b1 {
    width: 100%;
  }

  .b2 {
    width: 100%;
  }

  .join-text-box {
    width: 90%;
    margin: auto;
  }
}