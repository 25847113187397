.mubadala {
  padding-top: 70px;
  padding-bottom: 70px;
  background-color: #F5FDFB;
  margin-top: 100px;
}

.mubadala-container > h3 {
  width: 100%;
  text-align: center;
  width: 90%;
  margin: auto;
  color: #00507D;
  font-weight: 600;
  font-size: 38px;
}

.number-container > h4{
  color: #00507D;
}

.number-container > h2 {
  font-weight: lighter !important;
  font-size: 70px;
}

.numbers {
  padding-top: 50px;
  display: flex;
  justify-content: space-around;
  text-align: center;
  color: #75C6DB;
}

@media (max-width:850px) {
  .numbers {
    display: grid;
    grid-template-columns: repeat(2,1fr);
  }
}
