.about-home-container {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  justify-content: center;
  margin: auto;
  align-items: start;
}

.about-1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
}

.about-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.about-right > img {
  width: 100%;
  height: 400px;
}

.about-left > img {
  width: 100%;
  height: 400px;
}

.learn-more {
  display: flex;
  align-items: start;
  gap: 7px;
}

.learn-more > img {
  width: 40px;
  height: 25px;
}

.explaination-box {
  width: 75%;
  margin: 0;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 50px;
}

@media (max-width:850px) {
  .explaination-box {
    width: 90%;
    margin-top: 40px;
  }
}