.navbar {
  height: 80px;
  display: flex;
  align-items: center;
  padding:0 !important;
}

.nav {
  display: "flex";
  justify-content: space-between;
  background-color: white;
  padding: 0px 20px;
  width: 100%;
}

.list {
  text-decoration: none;
  list-style-type: none;
}

.list {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.list-box {
  width: 100%;
}

.img-box {
  width: 150px;
  height: 40px;
}

.nav-img {
  width: 100%;
  height: 90%;
}

.services {
  display: flex;
  align-items: center;
  gap: 5px;
  height: 100%;
  justify-self: center;
}

.services p {
  margin-top: 12px;
}

.down {
  height: 5px;
  width: 8px;
}

.list-item a {
  color: black;
  text-decoration: none;
}

.bts-img {
  width: 45%;
}

.bts-list {
  color: white;
  width: 70%,
}

.bts-list .navbar-nav {
  width:100%;
  display:flex;
  font-weight: 600;
  justify-content:space-around;
}

/* IPAD */
@media (max-width: 1000px) {
  .bts-img {
    width: 20%;
  }

  .nav {
    padding:0 !important;
  }

  .navbar>.container {
    padding:0 5px;
    margin: auto;
    max-width: 100%;
  }

  .navbar-light .navbar-toggler {
    color: transparent !important;
    padding: 0;
  }

  .bts-list {
    position: relative;
    right: -5%;
    top: 5%;
    background-color: rgba(255,255,255,1);
    display: flex;
    width: 100%;
    z-index: 5000;
    height: 200px;
  }

  .nav-itm {
    color: #00507d !important;
    margin-left: auto;
    padding-right: 5% !important;
  }

  .nav-itm a {
    color: #00507d !important;
    background-color: #F5FDFB;
    height: 100% !important;
    font-size: 18px !important;
  }

  .navbar {
    height: 60px;
  }

  .bts-list .navbar-nav {
    width:100%;
    height: 100%;
    display:flex;
    font-weight: 600;
    justify-content:start !important;
    align-items: start !important;
  }
}

/* Mobile */
@media (max-width: 850px) {
  .bts-img {
    width: 20%;
  }

  .nav {
    padding:0 !important;
  }

  .navbar>.container {
    padding:5px;
  }

  .navbar-light .navbar-toggler {
    color: transparent !important;
    padding: 0;
  }

  .bts-list {
    position: relative;
    right: -5%;
    top: 5%;
    background-color: rgba(255,255,255,1);
    display: flex;
    width: 100%;
    z-index: 5000;
    height: 200px;
  }

  .nav-itm {
    color: #00507d !important;
    margin-left: auto;
    padding-right: 5% !important;
  }

  .nav-itm a {
    color: #00507d !important;
    background-color: #F5FDFB;
    height: 100% !important;
    font-size: 18px !important;
  }

  .navbar {
    height: 60px;
  }

  .bts-list .navbar-nav {
    width:100%;
    height: 100%;
    display:flex;
    font-weight: 600;
    justify-content:start !important;
    align-items: start !important;
  }
}