.half-field {
  width: 100%;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  border: 0 !important;
}

.half-field>input {
  height: 50px;
  border: rgb(177, 177, 177) 1px solid;
  border-radius: 7px;
}

.half-field>input[type="checkbox"] {
  height: 20px;
  border: grey 1px solid;
}

input[type="radio"] {
  height: 30px;
  color: #00507d;
  background-color: #00507d;
}

.subject {
  width: 100% !important;
  height: 100px;
}

.full-field>input {
  width: 100%;
  height: 100px;
}

.comment {
  width: 100%;
  height: 100px;
}

.enquries h3 {
  text-align: center;
  width: 100%;
  font-weight: bold;
  color: #00507d;
}

label {
  font-weight: bold;
  margin-bottom: 20px;
}

.radio-label {
  margin-bottom: 0px;
}

.formation-1 {
  width: 75%;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  gap: 35px;
  padding-top: 50px;
}

.formation-2 {
  width: 75%;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr;
  justify-content: space-between;
  gap: 35px;
  padding-top: 50px;
}

.subscribe {
  display: grid !important;
  align-items: center !important;
  grid-template-columns: 3% 30% 63%;
  gap: 10px;
}

.subscribe>label {
  width: 160%;
}

.comments>input {
  border-radius: 7px !important;
  border: rgb(177, 177, 177) 1px solid;
}

.enquries {
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: #f9f9f9;
  margin-top: 100px;
  margin-bottom: 100px;
}

.selective {
  height: 50px;
  border-radius: 7px;
  border: rgb(177, 177, 177) 1px solid;
}

.sub {
  height: 50px;
  width: 40%;
  justify-self: end;
  color: white;
  background-color: #00507d;
  border: 0;
  border-radius: 7px;
}

select {
  width: 100%;
}

@media (min-width: 850px) {
  .sub-2 {
    display: none;
  }
}

@media (max-width:850px) {
  .formation-1 {
    width: 90%;
    grid-template-columns: 1fr;
  }

  .formation-2 {
    width: 90%;
    grid-template-columns: 1fr;
  }

  .subscribe {
    grid-template-columns: 10% 60%;
  }

  .sub {
    display: none;
  }

  .sub-2 {
    display: block;
    height: 50px;
    width: 100%;
    justify-self: end;
    color: white;
    background-color: #00507d;
    border: 0;
    border-radius: 7px;
  }

}