.carousel-home-1 {
  background-image: url("./assets/bg-1.png") !important;
  background-size: 100% 100vh;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
}

.carousel-home-2 {
  background-image: url("./assets/Group\ 156.png") !important;
  background-size: 100% 100vh;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
}

.carousel-home-3 {
  background-image: url("./assets/Group\ 152.png") !important;
  background-size: 100% 100vh;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
}

.carousel-home-4 {
  background-image: url("./assets/Group\ 154.png") !important;
  background-size: 100% 100vh;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
}

.carousel-home-5 {
  background-image: url("./assets/Group\ 155.png") !important;
  background-size: 100% 100vh;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
}

.carousel-line {
  background-color: transparent;
  display: flex;
  height: 100%;
  align-items: end;
  justify-content: space-between;
  color: white;
  padding: 0px 30px;

  .carousel-text {
    width: 70%;
    padding-bottom: 20px;
  }
}

.carousel-arrows {
  display: flex;
  gap: 10px;
  padding-bottom: 20px;
}

.arrow {
  height: 35px;
  width: 100%;
}

@media (max-width: 850px) {
  .arrow {
    display: none !important;
  }

  .carousel-home-1,
  .carousel-home-2,
  .carousel-home-3,
  .carousel-home-4,
  .carousel-home-5 {
    background-size: 200% 70vh;
    height: 70vh;
    background-position: -275px;
  }


  .carousel-text {
    width: 100% !important;
    padding-bottom: 20px;
  }
}

@media (max-width: 850px) {
  .arrow {
    display: none;
  }

  .carousel-home-1,
  .carousel-home-2,
  .carousel-home-3,
  .carousel-home-4,
  .carousel-home-5 {
    background-size: 200% 70vh;
    height: 70vh;
    background-position: -275px;
  }


  .carousel-text {
    width: 100% !important;
    padding-bottom: 20px;
  }
}