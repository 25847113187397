.about-home {
  width: 100%;
  margin: auto;
}


.about-home-container {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  justify-content: center;
  margin: auto;
  align-items: start;
}

.about-1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
  align-items: center;
}

.about-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap:  50px;
  align-items: center;
}

.about-right>img {
  width: 100%;
  height: 400px !important;
}

.about-left>h6 {
  font-size: 22px;
  font-weight: bold;
  margin: 0;
}

.about-left>h3 {
  font-size: 38px;
  font-weight: bold;
  margin: 0;
  margin-top: 10px;
}

.about-left>p {
  margin-top: 20px;
}

.about-right>h6 {
  font-size: 22px;
  font-weight: bold;
  margin: 0;
}

.about-right>h3 {
  font-size: 38px;
  font-weight: bold;
  margin: 0;
}

.about-right>p {
  margin-top: 20px;
}

.learn-more p {
  margin: 0;
}

.about-left>img {
  width: 100%;
  height: 400px !important;
}

.learn-more {
  display: flex;
  align-items: center !important;
  gap: 7px;
}

.learn-more>img {
  width: 30px !important;
  height: 15px !important;
}

@media (max-width: 850px) {
  .about-home-container {
    gap: 70px;
  }

  .about-home-container>div {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
  }

  .od-1 {
    order: 2;
  }

  .od-2 {
    order: 1;
  }

  .about-home {
    margin-top: 0 !important;
  }
}