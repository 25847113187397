.footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  height: 300px;
  background-color: #00507d;
  color: white;
  padding-top: 40px;
}

.single-img-box {
  display: flex;
  gap: 10px;
}

.footer-container {
  width: 75%;
  margin: auto;
}

.quick-links {
  display: flex;
  flex-direction: column;
  justify-content: right;
  text-align: right;
}

.quicking {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.quicking a {
  text-decoration: none;
  color: white;
}

.quicking a:hover {
  color: white;
}

.city {
  padding-top: 10px;
}

.l-h h4 {
  font-size: 26px;
  font-weight: bold;
}

.terms-container {
  width: 90%;
  display: flex;
  justify-content: end;
}

.footer-sect {
  display: flex;
  align-items: start;
  justify-content: space-between;
  width: 100%;
}

h4 {
  padding-bottom: 10px;
}

p {
  margin: 0;
}

.social-links {
  display: flex;
  align-items: center;
  gap: 5px;
}

.office-address {
  display: flex;
  gap: 10px;
  margin: 0;
}

.address>p {
  margin: 0;
}


@media (max-width: 850px) {
  .footer {
    height: 100%;
    padding-left: 20px;
    padding-bottom: 20px;
  }

  .footer-container{
    width: 75%;
  }

  .footer-sect {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 50px;
    width: 100%;
  }

  .quick-links {
    justify-content: left;
    text-align: left;
    gap: 20px;
  }

  .terms-container {
    justify-content: start;
    width: 75%;
    padding-top: 20px;
    margin: auto;
  }
}

/* Mobile */
@media (max-width: 850px) {
  .footer {
    height: 100%;
    padding-left: 20px;
    padding-bottom: 20px;
  }

  .footer-container{
    width: 100%;
  }

  .footer-sect {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 20px;
    width: 100%;
  }

  .quick-links {
    justify-content: left;
    text-align: left;
    gap: 0px;
  }

  .terms-container {
    justify-content: start;
    width: 90%;
    margin: inherit;
  }

  .number {
    margin:0;
  }

  .l-h h4 {
    margin: 0;
  }
}