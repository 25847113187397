.mission-container {
    width: 75%;
    margin: auto;
}

.mission-vission {
    margin-top: 100px;
    margin-bottom: 100px;
}

.bxx > h3 {
    font-size: 38px;
    margin-top: 40px;
    font-weight: bold;
}

@media (max-width:850px) {
    .mission-container {
        width: 90%;
    }
}