.docs-container {
  height: 850px;
  width: 100%;
  overflow: hidden;
  margin-top: 50px;
  margin-bottom: 50px;
}

.docs-container > img {
  width: 100%;
  height: 100%;
}

@media (max-width:850px) {
  .docs-container   {
    height: 200px;
  }

  .docs-container > img {
    object-fit: cover;
  }
}