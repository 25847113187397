.support-cards-container {
  width: 75%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: auto;
  justify-content: center;
  text-align: center;
  padding-top: 120px;
  padding-bottom: 120px;
  gap: 10px;
}

.grey {
  background-color: #F6F6F6 !important;
}

.support-img > img {
  height: 20px !important;
  width: 20px !important;
  margin-bottom: 10px;
  overflow: visible;
}

.support-link {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #00507D;
  text-decoration: none;
}

.support-anchor-arrow {
  margin-top: -15px;
}

.support-card {
  background-color: #f5fbff;
  padding-top: 50px;
  padding-bottom: 50px;
}

.support-text p {
  margin: 0;
}

.support-text h5 {
  font-weight: bold;
}

@media (max-width: 850px) {
  .support-cards-container {
    grid-template-columns: 1fr;
    width:90%;
  }
}