.position {
    height: 50px;
    background-color: rgb(192, 192, 192);
}

.position-container {
    width: 87.5%;  
    margin-left: auto;
}

@media (max-width:850px) {
    .position-container {
        width: 78.5%;  
    }    
}