body {
    background-color: white;
}

@media (max-width:850px) {

    .react-multiple-carousel__arrow--left,
    .react-multiple-carousel__arrow--right {
        display: none;
    }
}

img {
    object-fit: cover;
}