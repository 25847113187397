.about-about {
  margin-top: 50px;
}

.about-home-container {
  display: grid;
  grid-template-columns: 1fr;
  width: 75%;
  justify-content: center;
  margin: auto;
  align-items: start;
}

.about-1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
}

.about-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.about-right > img {
  width: 100%;
  height: 100%;
}

.about-left > img {
  width: 100%;
  height: 100%;
}

.learn-more {
  display: flex;
  align-items: start;
  gap: 7px;
}

.learn-more > img {
  width: 40px;
  height: 25px;
}

@media (max-width:850px) {
  .about-home-container {
    width: 90%;
    margin: auto;
  }
}