.providers {
  width: 75%;
  margin: auto;
}


.provider-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  margin-top: 100px;
  margin-bottom: 100px;
}

.provider-card-box {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
}

.provider-description-box h3{
  margin-bottom: 40px;
  font-weight: bold;
  font-size: 28px;
}

.provider-card {
  display: flex;
  align-items: start;
  gap: 15px;
}

.provider-img-box {
  margin-top: 15px;
}

@media (max-width: 850px) {
  .provider-container {
    grid-template-columns: 1fr;
    row-gap: 50px;
  }
  .providers {
    width: 90%;
  }
}
